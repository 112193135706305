import React, { Component } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import WatchlistContainer from './containers/Watchlist';
import { HOMEPAGE } from './config';


export default class App extends Component {
    render() {
        const router = createBrowserRouter([
            { path: "/", element: <WatchlistContainer /> },
        ]);

        return (
            <div className='p-4'>
                <RouterProvider router={router} />
            </div>
        )
    }
}
