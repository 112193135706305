import React, { Component } from 'react'

export default class Watchlist extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.props.getWatchlist().then(response => {
            if (response.data.code === '00') {
                this.setState({
                    data: response.data.data.data,
                    last_update: response.data.data.last_update
                })
            }
        })
    }

    colorCodeValue(prev, value, add_class='') {
        if (value == prev) {
            return <div className={`text-yellow-500 ${add_class}`}>{ value }</div>
        }
        else if (value > prev) {
            return <div className={`text-green-500 ${add_class}`}>{ value }</div>
        }
        else if (value < prev) {
            return <div className={`text-red-500 ${add_class}`}>{ value }</div>
        }
    }

    render() {
        return (
            this.state.data.length > 0 ?
                <>
                    <div className='grid grid-cols-5 gap-4 fixed top-0 left-0 p-4 bg-primary w-full'>
                        <div>
                            <div className='text-center text-main'>Code</div>
                        </div>
                        <div>
                            <div className='text-main'>Open</div>
                            <div className='text-main'>Prev</div>
                        </div>
                        <div>
                            <div className='text-main'>High</div>
                            <div className='text-main'>Low</div>
                        </div>
                        <div>
                            <div className='text-main'>Close</div>
                            <div className='text-main'>Change</div>
                        </div>
                        <div>
                            <div className='text-center text-main'>%</div>
                        </div>
                    </div>
                    <div className='mt-16'>
                        {this.state.data.map(item => {
                            return (
                                <div key={item.code} className='grid grid-cols-5 gap-3 my-3'>
                                    <div>
                                        <div className='bg-main rounded-md text-main text-center h-full'>
                                            <div className='text-main'>{ item.code }</div>
                                        </div>
                                    </div>
                                    <div>
                                        {this.colorCodeValue(item.prev, item.open)}
                                        <div className='text-main'>{ item.prev }</div>
                                    </div>
                                    <div>
                                        {this.colorCodeValue(item.prev, item.high)}
                                        {this.colorCodeValue(item.prev, item.low)}
                                    </div>
                                    <div>
                                        {this.colorCodeValue(item.prev, item.close)}
                                        {this.colorCodeValue(0, item.change)}
                                    </div>
                                    <div>
                                        {this.colorCodeValue(0, item.change_perc, 'text-center')}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='my-6 text-center text-main'>Last Update: {this.state.last_update}</div>
                </>
            :null
        )
    }
}
