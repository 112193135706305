import React from 'react'
import Watchlist from '../screens/Watchlist'
import { requestGet } from '../axios'


export default function WatchlistContainer(props) {
    async function getWatchlist(){
		try {
			return await requestGet('/stocks/')
		} 
        catch (error) {
            console.log(error)
		}
	}

    return <Watchlist 
        {...props} 
        getWatchlist={getWatchlist}
    />
}
